<template>
  <router-view />
</template>

<script>
export default {
  name: 'ErrorLayout'
}
</script>

<style>
  h1.page_error {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #252932;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
  }
</style>
